.friendReqsSection {
    background-color: #242836;
    width: 100%;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    & h1 {
        margin: 0;
        padding: 0 !important;
        font-size: 1.3em;
    }

    & .friendsList {
        display: flex;
        flex-direction: column;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 3px;
        }

        & .friend {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px;
            border-bottom: 1px solid #3a3f4b;

            & .friendInfo {
                display: flex;
                align-items: center;
                gap: 15px;
            }

            & .removeFriend {
                background-color: #ff4d4d;
                color: white;
                padding: 5px 5px;
                border-radius: 5px;
                cursor: pointer;
            }

            & .acceptFriend {
                background-color: #4dff4d;
                color: white;
                padding: 5px 5px;
                border-radius: 5px;
                cursor: pointer;
            }

            & .action {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        & .noFriend {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }
    }
}