@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

* {
    box-sizing: border-box !important;
}

html {
    -webkit-font-smoothing: antialiased;
}

html,
body {
    height: 100%;
    margin: 0;
}

.loadingHtml {
    // display: none;
}

body {
    position: relative;
    min-height: 100%;
    font-family: "Inter", sans-serif;
    color: black;
}